// ---------------------------
// Accordion Module
// ---------------------------

.accordion {
    .card {
        border: 0;
        border-radius: 0;
        border-top: 1px solid $grey-80;

        .card-header {
            background: none;
            padding: 0;
            text-align: left;
            border: 0;
            border-radius: 0;

            h5 {
                font-size: 1.5rem;
                font-weight: bold;

                > button {
                    padding: 1rem 0;
                    font-weight: bold;
                    width: 100%;
                    text-align: left;
                    position: relative;

                    &:after {
                        content: "\f077";
                        font-family: "Font Awesome 5 Free";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        font-size: 1.1rem;
                        transform: translateY(-50%);
                    }

                    &.collapsed {
                        &:after {
                            content: "\f078";
                        }
                    }
                }
            }
        }

        .card-body {
            padding: 0 0 1rem 0;
        }
    }
}
