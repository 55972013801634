// ---------------------------
// Modal Module
// ---------------------------

.modal {
    .modal-content {
        border-radius: 0;
        border: 0;

        .modal-header {
                padding: 1.2rem;
            border-bottom-color: $grey-80;
        }

        .modal-body {
            padding: 2rem 1.2rem;
        }
    }
}
