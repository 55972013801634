// ---------------------------
// Typography Module
// ---------------------------

h1 {
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: inherit;

    @include media-breakpoint-up(md) {
        font-size: 2.1rem;
    }
}

h2 {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: inherit;

    @include media-breakpoint-up(md) {
        font-size: 1.8rem;
    }
}

h3 {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: inherit;

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }
}

h4 {
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: inherit;

    @include media-breakpoint-up(md) {
        font-size: 1.3rem;
    }
}

.font-weight-black {
    font-weight: 900 !important;
}
