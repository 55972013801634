// ---------------------------
// Form Module
// ---------------------------

::placeholder {
    color: $grey-60;
    font-size: 0.85rem;
    letter-spacing: 1px;
}

:-ms-input-placeholder {
    color: $grey-60;
    font-size: 0.85rem;
    letter-spacing: 1px;
}

::-ms-input-placeholder {
    color: $grey-60;
    font-size: 0.85rem;
    letter-spacing: 1px;
}

.form-group, .input-group {
    margin-bottom: 1.6rem;

    label {
        font-size: 1.15rem;
        margin-bottom: 0.1rem;

        @include media-breakpoint-up(md) {
            font-size: 1.25rem;
        }
    }

    *:focus {
        outline: none !important;
    }

    small {
        float: left;
        margin-top: 0.25rem;
    }

    input[type="text"], input[type="password"], input[type="email"], input[type="phone"], textarea {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        padding: 0.25rem 0 0.5rem 0;
        box-shadow: none;
        transition: all .3s ease-in-out;
        background-image: linear-gradient(to top, $blue 2px, transparent 0),
            linear-gradient(to top, transparent 0, transparent 0);

        &:focus {
            background-image: linear-gradient(to top, $light-blue 2px, transparent 0),
                linear-gradient(to top, transparent 0, transparent 0);
        }
    }

    input[type="file"] {
        display: none;

        & + label {
            cursor: pointer;
            font-size: 1rem;
        }
    }
}

.form-check {
    padding: 0;
    margin-bottom: 2rem;

    input[type="checkbox"] {
        display: none;

        &+label {
            cursor: pointer;
            display: block;

            &:before {
                content: "\e835";
                font-family: 'Material Icons';
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.25rem;
                font-size: 1.4rem;
            }

            span {
                display: inline-block;
                vertical-align: text-top;
            }
        }

        &:checked+label {
            &:before {
                content: "\e834";
            }
        }
    }

    input[type="radio"] {
        display: none;

        &+label {
            cursor: pointer;
            display: block;

            &:before {
                content: "\e836";
                font-family: 'Material Icons';
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.25rem;
                font-size: 1.5rem;
            }

            span {
                display: inline-block;
                vertical-align: text-top;
            }
        }

        &:checked+label {
            &:before {
                content: "\e86c";
            }
        }
    }

    &.form-check-inline {
        margin-right: 0.75rem;
    }

    &.form-check-radio {
        margin-bottom: 0;
    }
}

select {
    border: 0;
    border-bottom: 1px solid $grey-80;
    padding: 0.3rem 0.25rem;
    background: none;
}
