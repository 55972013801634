// ---------------------------
// Links Module
// ---------------------------

a {
    transition: all .3s ease-in-out;

    @include hover-focus-active {
      text-decoration: none;
    }
}
