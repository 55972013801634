// ---------------------------
// Buttons Module
// ---------------------------

button, input[type="submit"] {
    transition: all .3s ease-in-out;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    letter-spacing: inherit;

    @include hover-focus-active {
        text-decoration: none !important;
        outline: 0 !important;
    }
}

.btn {
    font-size: 1.5rem;
    padding: 0.7rem 1.8rem;

    &--small {
        font-size: 1rem;
        padding: 0.25rem 0.75rem;

        .material-icons {
            font-size: 1.1rem;
        }
    }
}

.btn-highlight {
    position: fixed;
    bottom: 0;
    padding: 0.5rem 1.2rem;
    width: 100%;
    z-index: 101;
    border-radius: 0;
}

.btn-rounded {
    height: 45px;
    width: 45px;
    padding: 0;

    > * {
        line-height: 45px;
        font-size: 1.2rem;
    }

    @include media-breakpoint-up(lg) {
        height: 60px;
        width: 60px;

        > * {
            line-height: 60px;
            font-size: 1.4rem;
        }
    }

    &--small {
        height: 32px;
        width: 32px;
        font-size: 1rem;

        > * {
            line-height: 32px;
            font-size: 0.8rem;
        }

        @include media-breakpoint-up(lg) {
            height: 40px;
            width: 40px;

            > * {
                line-height: 40px;
                font-size: 1rem;
            }
        }
    }
}

@each $color, $value in $theme-colors {
    .btn-link-#{$color} {
        padding: 0;
        @include button-link-variant($value);
    }
}
