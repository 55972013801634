// ---------------------------
// Carousel Module
// ---------------------------

.carousel {
    .carousel-inner {
        .carousel-item {
            overflow: hidden;
        }
    }

    &#carousel-register {
        min-height: 250px;

        .carousel-inner {
            .carousel-item {
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    opacity: 0.3;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            min-height: auto;
        }
    }
}
