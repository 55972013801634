// ---------------------------
// List Module
// ---------------------------

.list-inline {
    > li {
        display: inline-block;
        vertical-align: middle;

        &:not(:last-child) {
            margin-right: 0.3rem;
        }
    }

    &--dashed {
        > li {
            &:not(:first-child) {
                &:before {
                    content: "-";
                    color: inherit;
                    margin-right: 0.3rem;
                }
            }
        }
    }
}
