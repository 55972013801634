// ---------------------------
// Layout
// ----------------------------

.app-content {
    transition: all .3s ease-in-out;

    &.fullscreen {
        footer {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            position: fixed;
            height: 100%;
            width: 100%;

            main {
                height: 100%;
            }
        }
    }

    &-sidebar {
        padding-top: 5.25rem;

        footer {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        &.swiped-header {
            .circle-visio {
                @include media-breakpoint-down(md) {
                    top: 0;
                }
            }

            @include media-breakpoint-down(md) {
                padding-top: 0;
            }
        }
    } 
    
    .logo {
        max-width: 130px;
    }
}
