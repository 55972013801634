// ---------------------------
// Buttons Mixin
// ---------------------------

@mixin button-link-variant($color, $hover-color: darken($color, 10%)) {
  color: $color;

  @include hover {
    color: $hover-color;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($color);
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }
}
