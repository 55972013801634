// ---------------------------
// Base
// ----------------------------

body {
    letter-spacing: 1px;
}

p {
    white-space: pre-wrap;
    overflow-wrap: break-word;
}
