// ---------------------------
// Helpers
// ---------------------------

.align-center {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.fa-1_5x {
    font-size: 1.5rem;
}
